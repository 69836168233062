import React from 'react'
import './CircleGradient.css'

const CircleGradient = () => {
  return (
    <div className='circlegradient-container'>
      
    </div>
  )
}

export default CircleGradient
